import YouTubePlayer from "youtube-player";

interface useYouTubeAnalyticsReturn {
  mountYouTubeAnalytics(el: HTMLElement, trackData: { collection: string; content: string }): void;
}

export default function useYouTubeAnalytics(): useYouTubeAnalyticsReturn {
  const mountYouTubeAnalytics = (el: HTMLElement, trackData: { collection: string; content: string }) => {
    const { trackClick } = useAnalytics();

    YouTubePlayer(el).on("stateChange", (event: { data: number }) => {
      const status: number = event.data;

      if (status === 1) {
        trackClick(trackData);
      }
    });
  };

  return {
    mountYouTubeAnalytics,
  };
}
