interface useSoundCloudAnalyticsReturn {
  mountSoundCloudAnalytics(el: HTMLElement, trackData: { collection: string; content: string }): void;
}

export default function useSoundCloudAnalytics(): useSoundCloudAnalyticsReturn {
  const mountSoundCloudAnalytics = (el: HTMLIFrameElement, trackData: { collection: string; content: string }): void => {
    if (typeof SC != "undefined") {
      mountAnalytics(el, trackData);
    } else {
      setTimeout(() => mountSoundCloudAnalytics(el, trackData), 100);
    }
  };

  const mountAnalytics = (el: HTMLIFrameElement, trackData: { collection: string; content: string }) => {
    const { trackClick } = useAnalytics();

    SC.Widget(el).bind("play", (e) => {
      if (e.currentPosition === 0) trackClick(trackData);
    });
  };

  return {
    mountSoundCloudAnalytics,
  };
}
