import { useTrackedClicks } from "@/composables/states";

const { mountSoundCloudAnalytics } = useSoundcloudAnalytics();
const { mountVimeoAnalytics } = useVimeoAnalytics();
const { mountYouTubeAnalytics } = useYouTubeAnalytics();

interface useAnalyticsReturn {
  mountAnalytics(el: HTMLElement, block: Block): void;
  trackClick(data: { collection?: string; content?: string }): Promise<void>;
}

export default function useAnalytics(): useAnalyticsReturn {
  const mountAnalytics = (el: HTMLElement, block: Block): void => {
    if (!el) return;

    // const { hashid } = useRoute().params;
    const hashid = null;
    const trackData = { collection: hashid, content: block.hashid };

    switch (block.item.embed?.type) {
      case "soundcloud":
        mountSoundCloudAnalytics(el, trackData);
        break;
      case "vimeo":
        mountVimeoAnalytics(el, trackData);
        break;
      case "youtube":
        mountYouTubeAnalytics(el, trackData);
        break;
    }
  };

  async function trackClick({ collection = null, content = null }: { collection?: string; content?: string }) {
    if (!collection && !content) return;

    const trackedClicks = useTrackedClicks();
    const hash = collection + content;

    if (trackedClicks.value.indexOf(hash) === -1) {
      trackedClicks.value.push(hash);

      // await $larafetch(`insights/event`, {
      //   method: "post",
      //   body: {
      //     event: "click",
      //     medium: "shared-landing-page",
      //     collection,
      //     content,
      //   },
      // });
    }
  }

  return {
    mountAnalytics,
    trackClick,
  };
}
