import Vimeo from "@vimeo/player";

interface useVimeoAnalyticsReturn {
  mountVimeoAnalytics(el: HTMLElement, trackData: { collection: string; content: string }): void;
}

export default function useVimeoAnalytics(): useVimeoAnalyticsReturn {
  const mountVimeoAnalytics = (el: HTMLIFrameElement, trackData: { collection: string; content: string }): void => {
    const { trackClick } = useAnalytics();
    new Vimeo(el).on("play", () => trackClick(trackData));
  };

  return {
    mountVimeoAnalytics,
  };
}
